import React, { useState } from 'react'
import { Form, Button, Row, Col, Alert } from 'react-bootstrap'
import emailjs from 'emailjs-com';

import './contact.scss'

const REACT_APP_SERVICE_ID = process.env.REACT_APP_SERVICE_ID  
const REACT_APP_TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID  
const REACT_APP_USER_ID = process.env.REACT_APP_USER_ID

export default function Contact() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [variant, setVariant] = useState('')

  function sendEmail(e) {

    if (name !== '' && message !== '' && email !== '') {
      emailjs.send(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID,{
        from_name: name,
        message: message,
        reply_to: email,
        }, REACT_APP_USER_ID
      ).then((result) => {
          setName('')
          setEmail('')
          setMessage('')
          setAlertMessage('Email sent successfully!')
          setVariant('success')
          setAlertOpen(true)
      }, (error) => {
          setAlertMessage('Something went wrong, please try again. If the issue persists, you can email us at contact@minnedev.com')
          setVariant('danger')
          setAlertOpen(true)
      })
    } else {
      setAlertMessage('All form fields are required, please fill them out. You are missing: ' + (name === '' ? 'Name ' : '') + (email === '' ? '\nEmail ' : '') + (message === '' ? '\nMessage to Minnedev' : ''))
      setVariant('danger')
      setAlertOpen(true)
    }

    
  }
  return (
    <div id="contact">
      <Row>
        <Col lg={8} id="contactForm">
          <h1>get in touch</h1>
          <Alert show={alertOpen} variant={variant} id="alert" dismissible onClose={() => setAlertOpen(false)}>
            <Alert.Heading />
            <p> {alertMessage} </p>
          </Alert>
          <Form>
            <Row className="g-2">
              <Col md>
                <Form.Group controlId="formBasicName">
                  <Form.Control 
                    required 
                    placeholder="name" 
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control required type="email" placeholder="email" onChange={(e) => setEmail(e.target.value)}/>
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control required as="textarea" placeholder="message" rows={3} onChange={(e) => setMessage(e.target.value)}/>
            </Form.Group>
            <Button 
              variant="outline-dark" 
              onClick={() => sendEmail({name, email, message})}
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  )
}