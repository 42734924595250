import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'

import Nav from './components/nav/nav'
import Home from './components/home/home'
import About from './components/about/about'
import Contact from './components/contact/contact'
import Gallery from './components/gallery/gallery'
import Footer from './components/footer/footer'

const firebaseConfig = {
  apiKey: "AIzaSyBpAc8DdgD7u-MhisMRINLVgdIuYHY3lfQ",
  authDomain: "minnedev-27f98.firebaseapp.com",
  projectId: "minnedev-27f98",
  storageBucket: "minnedev-27f98.appspot.com",
  messagingSenderId: "950908573047",
  appId: "1:950908573047:web:659877daf6b0e540fbfd72",
  measurementId: "G-5C8L90TBFM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <Router>
        <Nav />
        <div id="content">
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/gallery">
              <Gallery />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
      <Footer />
    </div>
  )
}

export default App
